import { render, staticRenderFns } from "./Queues.html?vue&type=template&id=03e5cf85&scoped=true&external"
import script from "./Queues.js?vue&type=script&lang=js&external"
export * from "./Queues.js?vue&type=script&lang=js&external"
import style0 from "./Queues.scss?vue&type=style&index=0&id=03e5cf85&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e5cf85",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FCallCenter%2FQueues%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports