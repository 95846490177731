import QueuesService from '@/services/queues.group.service';
import UserService from '@/services/user.service';
import Validations from './Validations';

const queuesService = new QueuesService(),
    userService = new UserService();


export default {
    name: 'queues',

    components: {},

    data() {
        return {
            queues: [],
            createdModal: false,
            updateModal: false,
            deleteModal: {
                Shown: false,
                Queue: null
            },
            agents: [],
            agentsCopy: [],

            agentSelected: null,
            agentGroupSelected: null,

            queuesGroup: {
                Agents: [],
                Name: '',
            },

            errors: {},
            searchVal: '',
            perPage: 10,
            totalRows: 0,
            currentPage: 1
        }
    },

    validations: Validations,

    async mounted() {
        await this.list();

        const res = await userService.myAgents();

        if (res && !res.error) {
            this.agents = res.data;
            this.agentsCopy = res.data;
        }
    },

    methods: {
        async cancelModal() {
            this.createdModal = false;
            this.updateModal = false;
            this.queuesGroup = {
                Agents: [],
                Name: '',
            };

            this.agents = this.agentsCopy.slice();
            this.errors = {};
        },

        async list(page) {
            this.currentPage = page || 1;
            let skip = this.perPage * (this.currentPage - 1);

            const res = await queuesService.list(this.perPage, skip, this.searchVal);
            if (res && !res.error) {
                this.queues = res.data.queues;
                this.totalRows = res.data.count;
            }
        },

        add(agent) {
            const index = this.agents.findIndex(a => a._id === agent._id);

            if (index >= 0) {
                this.queuesGroup.Agents.push(this.agents[index]);
                this.agents.splice(index, 1);
            }
        },

        remove(user) {

            const index = this.queuesGroup.Agents.findIndex(a => a._id === user._id);

            if (index >= 0) {
                this.agents.push(this.queuesGroup.Agents[index]);
                this.queuesGroup.Agents.splice(index, 1);
            }

        },

        addAll() {
            for (const user of this.agents) {
                this.queuesGroup.Agents.push(user);
            }

            this.agents = [];
        },

        removeAll() {
            for (const user of this.queuesGroup.Agents) {
                this.agents.push(user);
            }

            this.queuesGroup.Agents = [];
        },

        async save() {
            let res = null;

            this.queuesGroup.Agents = this.queuesGroup.Agents.map(a => a._id);

            if (this.updateModal === true) {
                res = await queuesService.edit(this.queuesGroup);
            } else {
                res = await queuesService.create(this.queuesGroup);
            }

            if (res && !res.error) {
                await this.list(this.currentPage || 1);
                await this.cancelModal();
            }

        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.list();
        },

        async edit(queue) {
            this.updateModal = true;
            this.agents = this.agentsCopy.slice();
            this.queuesGroup = queue;
            for (let user of this.queuesGroup.Agents) {
                this.agents = this.agents.filter(ele => ele._id !== user._id);
            }
        },

        showRemove(queue) {
            this.deleteModal.Queue = queue;
            this.deleteModal.Shown = true;
        },

        async removeGroup() {
            this.$showLoading();
            const res = await queuesService.remove(this.deleteModal.Queue._id);
            if (res && !res.error) {
                const index = this.queues.findIndex(g => g._id === this.deleteModal.Queue._id);
                if (index > -1) {
                    this.queues.splice(index, 1);
                }
                this.deleteModal.Shown = false;
            }
            this.$hideLoading();
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },
    },

    watch: {
        perPage() {
            this.list();
        }
    },
}

